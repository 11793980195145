import Vue from "vue";
// import Vuetify from "vuetify/lib";
import Vuetify from "vuetify";
// import "@mdi/font/css/materialdesignicons.css";
// import "../styles/App.scss";
import { Ripple } from "vuetify/lib/directives";

// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#385CEB",
        secondary: "#7737F9",
        support: "#37B9FA",
        accent: "#2A2D45",
        error: "#F23030",
        info: "#39f",
        success: "#22AD5C",
        warning: "#EF9909",
        anchor: "#321fdb",
        disabled: "#e4e5ed",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
