<template>
  <v-snackbar
    :key="`snack-v2-${message}`"
    v-model="showSnackbar"
    :timeout="timeout"
    :absolute="absolute"
    top
    :color="color"
    :left="left"
    :right="right"
    text
    :class="['snackbar-v2-wrapper', contentClass]"
    @input="toggleToast"
  >
    <div class="flex justify-between">
      <div class="title-toast">{{ title || "Inform" }}</div>
      <v-icon @click="closeToast">mdi-close</v-icon>
    </div>
    <div class="break-all">
      <template v-if="message">
        {{ message }}
      </template>
      <component :is="content" v-else />
    </div>
  </v-snackbar>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  props: {
    /** If **true** the snackbar will be displayed. */
    show: { type: Boolean, default: false },
    /** Time (in milliseconds) to wait until snackbar is automatically hidden. */
    timeout: { type: Number, default: 6000 },
    /** Applies **position: absolute** to the component. */
    absolute: { type: Boolean, default: true },
    /** Aligns the component towards the left. */
    left: { type: Boolean, default: false },
    /** Aligns the component towards the right. */
    right: { type: Boolean, default: true },
    /** Snackbar message. */
    message: { type: String, default: "" },
    /** Snackbar content. */
    content: { type: Object, default: null },
    /** Applies specified color to the control. */
    color: { type: String, default: "" },
    /** The snackbar color type. */
    type: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return (
          ["error", "info", "success", "warning"].includes(value) || !value
        );
      },
    },
    /** Snackbar title. */
    title: { type: String, default: "" },
    /** Function callback when user clicks on button close. */
    onClose: { type: Function, default: null },
  },
  data() {
    return {
      showSnackbar: this.show,
    };
  },
  computed: {
    contentClass() {
      return this.type ? `snackbar-v2-wrapper-${this.type}` : "";
    },
  },
  watch: {
    show(value) {
      this.showSnackbar = value;
    },
  },
  methods: {
    closeToast() {
      CommonUIControl.HideToastMessage();
      this.$emit("onClose");
    },
    toggleToast(value) {
      if (!value) this.closeToast();
    },
  },
};
</script>

<style lang="scss">
.snackbar-v2-wrapper {
  z-index: 1000;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border-width: 1px;
  .title-toast {
    font-size: 20px;
    font-weight: 500;
  }
  .v-snack__content {
    padding: 10px 0px 10px 15px;
  }
}

.snackbar-v2-wrapper-error {
  .v-snack__wrapper {
    color: var(--snack-error-text);
    background-color: var(--snack-error-bg);
    border-color: var(--snack-error-border);
  }
}
.snackbar-v2-wrapper-info {
  .v-snack__wrapper {
    background-color: var(--snack-info-bg);
    color: var(--snack-info-text);
    border-color: var(--snack-info-border);
  }
}
.snackbar-v2-wrapper-warning {
  .v-snack__wrapper {
    color: var(--snack-warning-text);
    background-color: var(--snack-warning-bg);
    border-color: var(--snack-warning-border);
  }
}
.snackbar-v2-wrapper-success {
  .v-snack__wrapper {
    color: var(--snack-success-text);
    background-color: var(--snack-success-bg);
    border-color: var(--snack-success-border);
  }
}
</style>
