<template>
  <div class="add-port-panel">
    <div
      v-for="(item, index) in portList"
      :key="item?.key || index"
      class="grid grid-cols-[1fr,1fr,80px] gap-x-2"
    >
      <Input
        :id="`rde-input-port-name-${index}`"
        v-model="item.name"
        placeholder="Enter your Port Name"
        :rules="[rules.required, rules.name, validateDuplicate('name')]"
        label="* Port Name"
        appendClass="input-text-field-v2 text-details"
      />
      <NumberInput
        :id="`rde-input-port-value-${index}`"
        v-model="item.port"
        format="none"
        placeholder="Enter your Port Number"
        :rules="[rules.required, validatePort, validateDuplicate('port')]"
        label="* Port"
        appendClass="input-text-field-v2 text-details"
        :max="65535"
        :min="0"
      />
      <div class="flex w-3">
        <v-icon
          v-show="portList.length > 1"
          class="h-6 mt-1"
          @click="handleRemoveClick(index)"
        >
          mdi-close
        </v-icon>
      </div>
    </div>
    <div v-if="!portList.length" class="text-xs text-error h-5">
      Require at least one port.
    </div>
    <Button class="add-button" size="md" @click="handleAddClick">
      <v-icon>mdi-plus</v-icon>
      ADD PORT
    </Button>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button/Button.vue";
import Input from "@/components/atoms/Input/Input.vue";
import NumberInput from "@/components/atoms/NumberInput/NumberInput.vue";
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  props: {
    portList: {
      type: Array,
      default: () => [],
    },
  },
  components: { Input, Button, NumberInput },
  mixins: [RulesMixin],
  emits: ["change"],
  methods: {
    validateDuplicate(name) {
      return (value) =>
        value &&
        this.portList &&
        this.portList?.filter((i) => i?.[name] === value)?.length > 1
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is duplicated!`
          : true;
    },
    validatePort(value) {
      if (value && [8443, 3000, 2222, 3333, 9931].includes(Number(value))) {
        return "8443, 3000, 2222, 3333, and 9931 is not allowed in RDE.";
      }
      return true;
    },
    handleAddClick() {
      const newDefaultPort = this.portList?.length
        ? Math.max(...this.portList.map((i) => i.port)) + 1
        : 8080;

      const newItems = [
        {
          key: Math.random(),
          name: "",
          protocol: "TCP",
          port: newDefaultPort,
          targetPort: newDefaultPort,
        },
        ...this.portList,
      ];
      this.$emit("change", newItems);
    },
    handleRemoveClick(index) {
      const newItems = this.portList.filter((_, i) => i !== index);
      this.$emit("change", newItems);
    },
  },
};
</script>

<style lang="scss">
.add-port-panel {
  padding: unset;
  margin: unset;
  margin-top: 10px;
  border: none;
  .add-button {
    border: none;
    background: #f5f5f5;
    font-size: 12px;
    font-weight: 700;
  }

  .text-details {
    .v-text-field__details {
      min-height: 14px !important;
    }
  }
}
</style>
