import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import helper from "./plugins/helper";
import Fragment from "vue-fragment";
import VueObserveVisibility from "vue-observe-visibility";
import Keycloak, { keycloakOptions } from "./plugins/keycloak";

import "@mdi/font/css/materialdesignicons.css";
import "./styles/external/roboto.css";
import "./styles/StyleVariable";
import "./components/global/GlobalComponents";
//import "./styles/tailwind.css";
import "./styles/tailwind_out.css";
import "./styles/App.scss";
import versionChecker from "./plugins/versionChecker";
import pipelineProperty from "./plugins/pipelineProperty";
import featuresSwitch from "./plugins/featuresSwitch";
import { requestNotificationPermission } from "./service/notification";
import { isManualRequestPermission } from "./helper/utils";

/** ===== 글로벌변수 ===== */
Vue.config.productionTip = false;
Vue.prototype.$appName = "MSA 개발 플랫폼";
Vue.prototype.$appSubName = "AMDP";
Vue.prototype.$appSubDesc = "(App Modernization Dev Platform)";

/** ===== setup msw ===== */
if (import.meta.env.MODE === "mock") {
  const { worker } = await import("./__mocks__/browser");
  worker.start({
    waitUntilReady: true,
    onUnhandledRequest: "bypass",
  });
}

(async () => {
  // # 키클락 관련 설정 동적으로 받아오기
  let keycloakConfig = {
    url: null,
    realm: null,
    clientId: null,
    useTopology: false,
  };

  keycloakConfig = (await axios.get("/v1/external/console-keycloak"))?.data
    ?.result;

  /** ===== plugins ===== */
  Vue.use(helper);
  Vue.use(VueObserveVisibility);
  Vue.use(featuresSwitch, keycloakConfig.useTopology);
  Vue.use(versionChecker);
  Vue.use(pipelineProperty);
  Vue.use(Fragment.Plugin);

  // this one for automation test, check if bypass login
  if (navigator.webdriver && localStorage.getItem("Authorization")) {
    const token = localStorage.getItem("Authorization");
    const isAuthorization = await axios({
      url: "/v1/user",
      method: "get",
      headers: { Authorization: token },
      timeout: 5000,
    }).then((r) => {
      return r?.data?.result?.username ? true : false;
    });

    if (isAuthorization === true) {
      new Vue({
        el: "#app",
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");

      return;
    }
  }

  Vue.use(
    Keycloak,
    keycloakOptions({
      router,
      url: keycloakConfig.url,
      realm: keycloakConfig.realm,
      clientId: keycloakConfig.clientId,
      onReady(data) {
        const { socketService } = data;

        Vue.prototype.$socketService = socketService;

        if (!isManualRequestPermission()) requestNotificationPermission();

        /** ===== 초기화 ===== */
        new Vue({
          el: "#app",
          router,
          store,
          vuetify,
          render: (h) => h(App),
        }).$mount("#app");
      },
    }),
  );
})();
