import httpClient from "../httpClient";

const apiUrls = {
  baseUrl: (path) => `/notification/api/${path}`,
  userSettingsApi: () => apiUrls.baseUrl("user-events"),
  listNotificationsApi: () => apiUrls.baseUrl("user-notifications"),
  totalUnreadCountApi: () => apiUrls.baseUrl("user-notifications/unread-count"),
  markAsReadApi: (notificationId) =>
    apiUrls.baseUrl(`user-notifications/${notificationId}`),
  markAllAsReadApi: () => apiUrls.baseUrl("user-notifications"),
};

export default {
  getTotalUnreadCount() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(apiUrls.totalUnreadCountApi())
        .then((res) => resolve(res.data?.totalUnreadCount))
        .catch((err) => reject(err.response));
    });
  },
  getNotifications(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(apiUrls.listNotificationsApi(), { params })
        .then((res) => {
          if (res?.data) return resolve(res.data || {});

          reject(res);
        })
        .catch((err) => reject(err.response || err));
    });
  },
  markAsRead(notificationId) {
    return new Promise((resolve, reject) => {
      httpClient
        .patch(apiUrls.markAsReadApi(notificationId), { isRead: true })
        .then((res) => resolve(res?.data))
        .catch((err) => reject(err.response));
    });
  },
  markAllAsRead() {
    return new Promise((resolve, reject) => {
      httpClient
        .patch(apiUrls.markAllAsReadApi())
        .then((res) => resolve(res?.data))
        .catch((err) => reject(err.response));
    });
  },
  getUserNotificationSettings() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(apiUrls.userSettingsApi())
        .then((res) => resolve(res?.data || []))
        .catch((err) => reject(err.response));
    });
  },
  saveUserNotificationSettings(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(apiUrls.userSettingsApi(), data)
        .then((res) => resolve(res?.data))
        .catch((err) => reject(err.response));
    });
  },
};
