
<v-checkbox
  :id="id"
  class="atoms__checkbox"
  :class="[simply && 'simple']"
  :inputValue="innerValue"
  :label="label"
  hide-details
  v-bind="$attrs"
  v-on="$listeners"
  @change="handleCheckboxChange"
>
  <template #label>
    <slot name="label"></slot>
  </template>
  <template>
    <slot></slot>
  </template>
</v-checkbox>
