<template>
  <div class="rde-catalog-table">
    <div class="flex justify-between mb-1">
      <div class="flex items-center gap-2">
        <Input
          v-if="!hideSearch"
          v-model="searchText"
          label="Search"
          hide-details
          append-icon="mdi-magnify"
          outlined
          appendClass="app-g-input-v2 max-w-80 rde-input-search"
          placeholder="Please enter your search"
        />
        <slot name="filter-extra"></slot>
      </div>

      <div>
        <slot name="toolbar"></slot>
      </div>
    </div>
    <v-data-table
      :headers="finalHeaders"
      :items="viewItems"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :item-key="itemKey"
      :item-value="itemValue"
      :show-expand="showExpand"
      dense
      class="table-wrapper"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="!ignoreTdSpanDetail" :colspan="headers.length">
          <slot name="expanded-item" :item="item"></slot>
        </td>
        <slot v-else name="expanded-item" :item="item"></slot>
      </template>
      <template
        v-slot:item="{
          item,
          headers,
          index,
          isExpanded,
          expand,
          isSelected,
          select,
        }"
      >
        <tr>
          <td v-for="header in headers" :key="header.value">
            <div
              v-if="header.value === 'actions'"
              class="flex flex-row justify-center gap-1"
            ></div>

            <v-icon
              v-else-if="
                header.value === 'data-table-expand' && showExpandByRow(item)
              "
              @click="expand(!isExpanded)"
            >
              {{ isExpanded ? "mdi-chevron-down" : "mdi-chevron-right" }}
            </v-icon>

            <Checkbox
              v-else-if="header.value === 'data-table-select'"
              :value="isSelected"
              color="primary"
              class="pr-2"
              @click.stop="select(!isSelected)"
            />

            <!-- Check if there is a custom slot for this column -->
            <slot
              v-else
              :name="`item.${header.value}`"
              :item="item"
              :header="header"
              :index="index"
            >
              <!-- Default content if no custom slot is provided -->
              <span class="line-clamp-2 break-all">
                {{ item[header.value] }}
              </span>
            </slot>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    itemKey: {
      type: [String, Number],
      default: "",
    },
    itemValue: {
      type: [String, Number],
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    ignoreTdSpanDetail: {
      type: Boolean,
      default: false,
    },
    showExpandByRow: {
      type: Function,
      default: () => true,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      searchText: "",
    };
  },
  computed: {
    finalHeaders() {
      if (this.showExpand) {
        return [
          {
            text: "",
            value: "data-table-expand",
            sortable: false,
            width: "5%",
            minWidth: 40,
          },
          ...this.headers,
        ];
      }
      return this.headers;
    },
    viewItems() {
      if (this.searchText) {
        return this.items.filter((item) => {
          return Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value
                .toLowerCase()
                .includes(this.searchText.trim().toLowerCase()),
          );
        });
      }
      return this.items;
    },
  },
};
</script>

<style type="scss">
.rde-catalog-table {
  .table-wrapper {
    border: 1px solid var(--gray9);
    color: #00000099;
    font-size: 12px;

    tbody tr {
      background-color: var(--text-white) #ffffff; /* White for even rows */
      td {
        color: #343c6a;
        font-weight: 400;
        padding: 0px 10px !important;
      }
      height: 34px;
    }

    thead {
      background-color: var(--gray12) !important;
      border-bottom: 1px solid var(--gray9);

      tr > th {
        padding-left: 10px !important;
        vertical-align: middle;
        height: 41px !important;
        background-color: var(--gray12) !important;
        &:has(> div.v-data-table__checkbox.v-simple-checkbox) {
          background-color: lightgray;
          padding-left: 14px !important;
        }
      }

      tr > th > span,
      td > th > span {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: var(--text-primary);
      }

      tr {
        height: 38px !important;
      }
      th {
        height: 38px !important;
        border: none !important;
        border-right-color: white;
      }

      th:first-child {
        border-radius: 6px 0 0 0;
      }

      th:last-child {
        border-radius: 0 6px 0 0;
        border-right-color: var(--background-tetiary) !important;
      }
      & .desc {
        width: 100% !important;
        align-items: center !important;
        padding: unset !important;
        padding-left: 10px !important;
      }
    }

    .v-data-table__wrapper {
      min-height: 70px;
      border: 1px solid var(--border-primary);
      border-radius: 6px;
    }
  }
}
</style>
