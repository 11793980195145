<template>
  <div class="mt-0">
    <div class="flex m-1 gap-2 checkbox-list">
      <Checkbox
        id="vscode-use-git"
        :value="values.vscode.useGit"
        label="Git"
        :simply="false"
        @input="onChangeUseGit"
      />
      <Checkbox
        id="vscode-use-extension"
        v-model="values.useExtention"
        :simply="false"
        label="Extension"
      />
      <Checkbox
        id="vscode-use-aiExtension"
        v-model="values.useAIExtention"
        :simply="false"
        label="AI code assistant"
      />
      <Checkbox
        v-if="showImageCheckbox"
        id="vscode-use-image"
        v-model="values.vscode.useImage"
        :simply="false"
        label="Image"
        @input="(value) => setValue('vscode.useImage', value)"
      />
    </div>

    <div v-if="values.vscode.useGit === true">
      <Input
        id="input-git-id"
        v-model="values.vscode.git.id"
        label="* Git ID"
        appendClass="input-text-field-v2"
        placeholder="Enter your Git ID"
        :rules="[rules.required]"
      />
      <Input
        id="input-git-repository"
        v-model="values.vscode.git.repository"
        appendClass="input-text-field-v2"
        label="* Repository"
        placeholder="Enter the repository"
        :rules="[rules.required, rules.gitUrl]"
      />
      <Input
        id="input-git-branch"
        v-model="values.vscode.git.branch"
        appendClass="input-text-field-v2"
        label="* Branch"
        placeholder="Enter the branch"
        :rules="[rules.required]"
      />
      <Input
        id="input-git-token"
        v-model="values.vscode.git.token"
        appendClass="input-text-field-v2"
        label="Token"
        placeholder="Enter Token"
      />
    </div>

    <v-autocomplete
      v-if="values.useExtention === true"
      id="select-extension-packages"
      v-model="values.packageType"
      label="Extention packages"
      :items="extentionPackageOptions"
      itemText="displayName"
      itemValue="name"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      :rules="[rules.required]"
    >
      <template v-slot:item="data">
        <v-list-item v-bind="data.attrs" @click="toggleSelection(data.item)">
          <v-list-item-action>
            <v-checkbox
              v-model="data.attrs.inputValue"
              :value="data.item.value"
              :ripple="false"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-if="values.useAIExtention === true"
      id="select-ai-extension-packages"
      v-model="values.aiPackageType"
      label="AI code assistant packages"
      :items="aiExtentionPackageOptions"
      itemText="displayName"
      itemValue="name"
      outlined
      dense
      clearable
      class="app-g-autocomplete-v2"
      :rules="[rules.required]"
    >
      <template v-slot:item="data">
        <v-list-item v-bind="data.attrs" @click="selectAI(data.item)">
          <v-list-item-content class="mb-1 mt-2">
            <v-list-item-title>
              {{ data.item.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <!-- currently, we hide install files -->
    <div v-if="false">
      <Checkbox
        id="vscode-install-file"
        v-model="values.vscode.useFiles"
        label="Install Files"
      />
    </div>
    <v-autocomplete
      v-if="false"
      id="vscode-select-file"
      v-model="values.vscode.files"
      label="Add Files"
      :items="[
        { label: 'File 1', value: 'abc' },
        { label: 'File 2', value: 'bdf' },
      ]"
      :itemText="(a) => a.label"
      :itemValue="(a) => a.value"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="rde-autocomplete"
      :rules="[rules.required]"
    />
  </div>
</template>

<script>
import Checkbox from "@/components/atoms/Checkbox/Checkbox.vue";
import Input from "@/components/atoms/Input/Input.vue";
import { RulesMixin } from "@/mixins/RulesMixin";
import { getExtentionRDEList } from "@/service/apis/workspaceApis";
import _ from "lodash";

export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
    showImageCheckbox: { type: Boolean, default: false },
    defaultValues: { type: Object },
  },
  components: { Checkbox, Input },
  mixins: [RulesMixin],
  data: () => ({
    addType: "",
    extentionPackageOptions: [],
    aiExtentionPackageOptions: [],
    AI_EXTENTION_KEY: "ai-extension",
  }),
  watch: {
    defaultValues: {
      immediate: true,
      handler() {
        if (this.defaultValues && !_.isEmpty(this.defaultValues)) {
          this.mapAIpackage();
        }
      },
    },
    aiExtentionPackageOptions: {
      immediate: true,
      handler() {
        if (this.aiExtentionPackageOptions.length) {
          this.mapAIpackage();
        }
      },
    },
  },
  methods: {
    onChangeUseGit(value) {
      this.setValue("vscode.useGit", value);
      if (value && !this.values.vscode.git) this.setValue("vscode.git", {});
    },
    toggleSelection(item) {
      if (
        !this.values?.packageType?.length ||
        this.values?.packageType?.every((i) => i !== item.name)
      ) {
        if (_.isNull(this.values.packageType)) {
          this.values.packageType = [];
        }
        this.setValue("packageType", [
          ...(this.values.packageType || {}),
          item.name,
        ]);
      } else {
        this.setValue(
          "packageType",
          this.values.packageType?.filter((i) => i !== item.name),
        );
      }
    },
    selectAI(item) {
      if (
        !this.values.aiPackageType ||
        this.values.aiPackageType !== item.name
      ) {
        this.setValue("aiPackageType", item.name);
      } else {
        this.setValue("aiPackageType", "");
      }
    },
    mapAIpackage() {
      if (
        !this.aiExtentionPackageOptions.length ||
        _.isEmpty(this.defaultValues)
      )
        return;

      const aiPackageType = this.aiExtentionPackageOptions.find((ai) =>
        this.defaultValues.packageType.some((p) => p === ai.name),
      )?.name;

      if (aiPackageType) {
        const newPackageTypes = (this.defaultValues.packageType || []).filter(
          (p) => aiPackageType !== p,
        );
        this.setValue("useExtention", !!newPackageTypes.length);
        this.setValue("packageType", newPackageTypes);

        this.setValue("aiPackageType", aiPackageType);
        this.setValue("useAIExtention", true);
      }
    },
  },
  mounted() {
    getExtentionRDEList().then((res) => {
      this.extentionPackageOptions = (res?.data?.data || []).filter(
        (e) => e.type !== this.AI_EXTENTION_KEY,
      );
      this.aiExtentionPackageOptions = (res?.data?.data || []).filter(
        (e) => e.type === this.AI_EXTENTION_KEY,
      );
    });
  },
};
</script>

<style lang="scss" scoped>
.checkbox-list {
  .v-input--selection-controls {
    margin-top: 0px !important;
  }
}
</style>
