<template>
  <div class="file-environment-panel">
    <div
      v-for="(item, index) in mountedConfigList"
      :key="`file-env-${index}`"
      class="grid grid-cols-4 gap-x-2 pb-3"
    >
      <v-autocomplete
        :id="`rde-input-file-env-name-${index}`"
        v-model="item.name"
        :items="genOptions(item.name)"
        label="* Resource Name"
        small-chips
        placeholder="Select Resource Name"
        class="app-g-autocomplete-v2 v-auto-help-message"
        outlined
        dense
        :rules="[rules.required]"
        :messages="
          item.name
            ? `Type: ${item.mountedType}, Config type: ${item.configType}`
            : ''
        "
        @change="(value) => onResourceNames(item, value)"
      />
      <v-icon class="h-6 w-5 pt-5" @click="handleRemoveClick(index)">
        mdi-close
      </v-icon>
    </div>
    <Button class="add-button" size="md" @click="handleAddClick">
      <v-icon>mdi-plus</v-icon>
      ADD File/Environment value mount
    </Button>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button/Button.vue";
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  props: {
    mountedConfigList: {
      type: Array,
      default: () => [],
    },
    installPackages: { type: Object, default: () => ({}) },
    resourceNames: { type: Array, default: () => [] },
  },
  components: { Button },
  mixins: [RulesMixin],
  emits: ["change"],
  data: function () {
    return {
      //resourceNames: this.fileEnvMountConfigList,
    };
  },
  watch: {
    // installPackages: {
    //   handler: function () {
    //     this.resourceNames = compact(
    //       uniq(
    //         flattenDeep(
    //           Object.values(this.installPackages?.mount || {})?.map(
    //             (configTypes) => Object.values(configTypes),
    //           ),
    //         ),
    //       ),
    //     );
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    genOptions(value) {
      return this.resourceNames?.filter(
        (option) =>
          option === value ||
          !this.mountedConfigList?.length ||
          this.mountedConfigList.every((cur) => cur.name !== option),
      );
    },
    onResourceNames(item, value) {
      if (!value) {
        item.mountedType = "";
        item.configType = "";
      } else {
        Object.entries(this.installPackages?.mount || {}).forEach(
          ([mountType, configTypes]) => {
            Object.entries(configTypes).forEach(
              ([configType, resourceNames]) => {
                if (resourceNames.includes(value)) {
                  item.mountedType = mountType;
                  item.configType = configType;
                }
              },
            );
          },
        );
      }
    },
    handleAddClick() {
      const newItems = [
        {
          mountedType: "",
          configType: "",
          name: "",
        },
        ...this.mountedConfigList,
      ];
      this.$emit("change", newItems);
    },
    handleRemoveClick(index) {
      const newItems = this.mountedConfigList.filter((_, i) => i !== index);
      this.$emit("change", newItems);
    },
  },
};
</script>

<style lang="scss">
.file-environment-panel {
  padding: unset;
  margin: unset;
  margin-top: 10px;
  border: none;
  .add-button {
    border: none;
    background: #f5f5f5;
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
