export default [
  {
    path: "/Setup/notifications",
    name: "NotificationConfiguration",
    component: () =>
      import(
        "../pages/main/modernframework/Setup/Notification/NotificationConfiguration.vue"
      ),
  },
];
