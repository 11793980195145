import {
  getNotificationPublicIconUrl,
  getNotificationRouteUrl,
} from "@/helper/notificationHelper";
import router from "@/router";
import notificationApis from "@/service/apis/notificationApis";
import store from "@/store";

const notificationKey = "showNotification";
let resetTimeout = null;

export const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification.");
  }

  try {
    const permission = await Notification.requestPermission();

    console.log("Notification permission :>> ", permission);

    return permission;
  } catch (error) {
    console.error("Error getting notification permission or token:", error);
  }

  return "default";
};

export const showNotification = (msg = {}) => {
  const {
    id: notificationId,
    title,
    message,
    icon,
    createdAt,
    ...options
  } = msg;

  const isShowingNotification = localStorage.getItem(notificationKey);

  clearTimeout(resetTimeout);
  resetTimeout = setTimeout(resetNotification, 50);

  if (isShowingNotification || Notification.permission !== "granted") return;

  const notification = new Notification(title, {
    ...options,
    body: message,
    icon: getNotificationPublicIconUrl(icon),
    tag: createdAt,
  });
  localStorage.setItem(notificationKey, true);

  notification.onclick = async (event) => {
    event.preventDefault();

    await notificationApis
      .markAsRead(notificationId)
      .then(() => store.commit("readNotification", notificationId))
      .finally(() => {
        const routeUrl = getNotificationRouteUrl(msg);

        window.focus();
        router.push(routeUrl);
        notification.close();
      });
  };

  notification.close = resetNotification;
};

const resetNotification = () => {
  localStorage.removeItem(notificationKey);
};
