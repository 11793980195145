import _ from "lodash";

/**
 * data에서 지정된 형식에 따라 정보를 반환한다
 */
export const getFieldData = (data, field) => {
  const type = typeof field;

  if (type === "undefined") {
    return data;
  }
  if (type === "string") {
    return data[field];
  }
  if (type === "function") {
    return field(data);
  }
};

/**
 * 지정된 함수를 지연 시킨 후 실행 한다
 * @param {function} f 지연된 시간 후 실행시킬 함수. 리턴값을 반환한다
 * @param {number} time
 * @returns Promise<unknown>
 */
export const lazy = (f, time = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(f()), time));

/**
 * version1과 version2를 비교
 * @param {string} v1
 * @param {string} v2
 * @example
 *  biggerVersion("0.1.2", "0.1.1") // 1
 *  biggerVersion("0.1.2", "0.1.2") // 0
 *  biggerVersion("0.1.2", "0.1.3") // -1
 */
export const biggerVersion = (v1, v2) => {
  const v1arr = v1.split(".");
  const v2arr = v2.split(".");
  let i = 0;

  if (v1 === v2) {
    return 0;
  }

  for (const a of v1arr) {
    a;
    if (v1arr[i] > v2arr[i]) {
      return 1;
    }
    if (v1arr[i] < v2arr[i]) {
      return -1;
    }
    i++;
  }

  return -1;
};

/**
 * JSON 배열을 오브젝트로 변환한다.
 * @param {JSONArray} arr JSON 배열 객체
 * @param {function} f 반환값이 키값이 된다
 * @example
 */
export const arrToObj = (arr, f) =>
  arr.reduce(
    (acc, cur) => ({
      ...acc,
      [f(cur)]: cur,
    }),
    {},
  );

export default {
  checkEmailFormat: (email) => {
    if (email === null) {
      return false;
    }
    if (_.isEmpty(email)) {
      return false;
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  },
  extractChangedKeyValue(original, changed) {
    const result = {};
    for (const key of Object.keys(changed)) {
      if (original[key] !== changed[key]) {
        result[key] = changed[key];
      }
    }
    return result;
  },
  toKoreanTimeString(t) {
    // new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '')
    return new Date(+new Date(t) + 3240 * 10000)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "");
  },
  /**
   * 프로젝트에 속하는 마이크로서비스 목록과 파이프라인 목록을 사용하여 배포네임스페이스를 가지는
   * 첫번째 마이크로서비스의 배포네임스페이스를 리턴한다.
   * @param {Array} microservices 프로젝트에 속하는 마이크로서비스 목록
   * @param {Array} pipelines 프로젝트에 속하는 파이프라인 목록
   * @returns
   */
  getIngressNamespace(microservices, pipelines) {
    const pipelineMsIdNamespaceMap = {};
    pipelines.forEach((pipeline) => {
      pipelineMsIdNamespaceMap[pipeline.microserviceId] =
        pipeline.clusterNamespace;
    });

    let msWithClustername = microservices
      //마이크로서비스 중 파이프라인에 네임스페이스가 존재하는 경우
      .filter((m) => !!pipelineMsIdNamespaceMap[m.id]);
    //가장 먼저 생성된 마이크로서비스를 찾기 위해 정렬
    msWithClustername.sort((m1, m2) => m1.id - m2.id);
    let firstMicroservice = msWithClustername[0]; //첫번쨰 아미크로서비스 선택
    return firstMicroservice
      ? pipelineMsIdNamespaceMap[firstMicroservice.id]
      : "";
  },
  ripple: function ($el) {
    let ev = new Event("mousedown");
    let offset = $el.getBoundingClientRect();
    // ev.clientX = offset.left + offset.width/2
    // ev.clientY = offset.top + offset.height/2
    ev.clientX = (offset.left + offset.right) / 2;
    ev.clientY = (offset.top + offset.bottom) / 2;
    $el.dispatchEvent(ev);

    setTimeout(function () {
      $el.dispatchEvent(new Event("mouseup"));
    }, 300);
  },
  cronExpressionToKorean(exp) {
    if (!exp || typeof exp !== "string") return "";
    const expsplit = exp.split(" ");
    const min = expsplit[0]; //0-59
    const hour = expsplit[1]; //0-23
    const day = expsplit[2]; //1-31
    // const month = expsplit[3] //1-12 JAN-DEC
    const week = expsplit[4]; //0-6 SUN-SAT
    let str = [];
    const weekmap = {
      SUN: "일",
      MON: "월",
      TUE: "화",
      WED: "수",
      THU: "목",
      FRI: "금",
      SAT: "토",
    };
    //요닐 또는 일자
    if (day === "*" || week === "*") str.push("매일");
    else if (week === "?") str.push("매주 " + day + "일");
    else if (day === "?") {
      str.push(
        "매주 " +
          week
            .split(",")
            .map((w) => weekmap[w])
            .join(",") +
          "요일",
      );
    }
    //  else {
    //   //현재 미지원
    // }

    str.push(hour + "시");
    str.push(min + "분");

    return str.join(" ");
  },
  copyToClipboard(textToCopy) {
    //https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      // textArea.style.position = "fixed";
      textArea.style.position = "absolute";
      textArea.style.opacity = 0;
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  },

  arrayEquals(a, b) {
    if (!(Array.isArray(a) && Array.isArray(b))) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    let bFind = false;
    for (let i = 0; i < a.length; ++i) {
      bFind = !!b.find((it) => it === a[i]);
    }
    return bFind;
  },
};

export const roundNumber = (number, digit = 2) => Number(number.toFixed(digit));

export const isChrome = () =>
  /Chrome/.test(navigator.userAgent) &&
  /Google Inc/.test(navigator.vendor) &&
  !/OPR|Edg|Brave/.test(navigator.userAgent);

export const isFirefox = () => /firefox/i.test(navigator.userAgent);

export const isEdge = () => /edg/i.test(navigator.userAgent);

export const isManualRequestPermission = () => !isChrome();

export const generatePathUrl = (data) => {
  return Object.entries(data).reduce(
    (acc, [key, value]) => (value ? `${acc}/${key}/${value}` : acc),
    "",
  );
};
