<template>
  <v-switch
    :id="id"
    v-model="checked"
    class="atoms__sw"
    :class="[appendClass]"
    :value="true"
    v-bind="$attrs"
    :color="color"
    :disabled="disabled"
    v-on="$listeners"
    @change="handleChange"
  ></v-switch>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    value: {
      type: Boolean,
      default: undefined,
    },
    appendClass: {
      type: String,
    },
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emitts: ["input"],
  data: () => ({
    checked: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.checked = !!newValue;
      },
    },
  },
  methods: {
    handleChange(newSw) {
      this.$emit("input", !!newSw);
    },
  },
};
</script>

<style lang="scss">
.atoms__sw {
  display: inline-flex;
  margin-top: 0px;
  padding-top: 0px;
  flex: initial !important;
  .v-input--selection-controls__ripple {
    left: -6px !important;
  }
}
</style>
