import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProjectGuard from "@/components/common/ProjectGuard/ProjectGuard";
import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "Setup",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "PipelineTemplateList",
        name: "PipelineTemplateList",
        component: () =>
          import(
            "../pages/main/modernframework/PipelineV2/PipelineTemplateListV2.vue"
          ),
      },
    ],
  },
  {
    path: "/projects",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProjectGuard,
        children: [
          {
            path: ":projectId/profiles/:profileId/pipelines/:pipelineId",
            name: "PipelineDetail",
            component: () =>
              import(
                "../pages/main/modernframework/PipelineDetailV2/PipelineDetailV2.vue"
              ),
          },
          {
            path: ":projectId/profiles/:profileId/microservices/:microserviceId/Pipeline/:pipelineId",
            name: "PipelineDetailMID", //마이크로서비스 ID 만으로 파이프라인 가져오는 구현이 반영됨
            component: () =>
              import(
                "../pages/main/modernframework/PipelineDetailV2/PipelineDetailV2.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "ProjectDetail/:projectId/profiles/:profileId/pipelineCode/:filename",
    name: "ProjectCodeTemplateFileDetail", //마이크로서비스 ID 만으로 파이프라인 가져오는 구현이 반영됨
    component: () =>
      import(
        "../pages/main/modernframework/ProfileV2/ProfileDetailV2/PipelineSetupV2/PipelineCodeV2/PipelineCodeV2.vue"
      ),
  },
  {
    path: "CodeTemplateDetail/:templateId",
    name: "CodeTemplateDetail",
    component: () =>
      import(
        "../pages/main/modernframework/PipelineV2/PipelineTemplateDetailV2.vue"
      ),
  },
  {
    path: "CodeTemplateDetail/project/:projectId",
    name: "ProjectCodeTemplateDetail",
    component: () =>
      import("../pages/main/modernframework/Pipeline/CodeTemplateDetail.vue"),
    meta: {
      type: "detail",
    },
  },
  {
    path: "PipelineTemplateCreate",
    name: "PipelineTemplateCreate",
    component: () =>
      import(
        "../pages/main/modernframework/PipelineV2/PipelineTemplateCreate.vue"
      ),
  },
  {
    path: "PipelineTemplateEdit/:templateId",
    name: "PipelineTemplateEdit",
    component: () =>
      import(
        "../pages/main/modernframework/PipelineV2/PipelineTemplateEdit.vue"
      ),
  },
  {
    path: "PipelineFileCreate/:templateId",
    name: "PipelineFileCreate",
    component: () =>
      import("../pages/main/modernframework/PipelineV2/Jenkins/FileCreate.vue"),
  },
  {
    path: "PipelineFileEdit/:templateId/:fileIdx",
    name: "PipelineFileEdit",
    component: () =>
      import("../pages/main/modernframework/PipelineV2/Jenkins/FileEdit.vue"),
    props: true,
  },
  {
    path: "PipelineTaskCreate/:templateId",
    name: "PipelineTaskCreate",
    component: () =>
      import("../pages/main/modernframework/PipelineV2/Tekton/TaskCreate.vue"),
  },
  {
    path: "PipelineTaskEdit/:templateId/:taskIdx",
    name: "PipelineTaskEdit",
    component: () =>
      import("../pages/main/modernframework/PipelineV2/Tekton/TaskEdit.vue"),
  },
  {
    path: "PipelineCreate/:templateId",
    name: "PipelineCreate",
    component: () =>
      import(
        "../pages/main/modernframework/PipelineV2/Tekton/PipelineCreate.vue"
      ),
  },
  {
    path: "PipelineEdit/:templateId/:pipelineIdx",
    name: "PipelineEdit",
    component: () =>
      import(
        "../pages/main/modernframework/PipelineV2/Tekton/PipelineEdit.vue"
      ),
  },
  {
    path: "projects/:projectId/profiles/:profileId/microservices/:microserviceId/Pipeline/:pipelineId/run/:commitrunId",
    name: "PipelineRunResult",
    component: () =>
      import(
        "../pages/main/modernframework/Pipeline/PipelineExecutionDetailsV2.vue"
      ),
    meta: {
      type: "detail",
    },
    props: (route) => ({ pipelineCommitRun: route.params.commitRun }),
  },
];
