
<label v-if="label">
  {{ label }} <span v-if="isRequired" class="requiredSyntax">*</span>
  <TooltipV2 v-if="hasTooltipSlot" contentClass="inline">
    <img
      class="inline w-4 h-4 ml-1 mb-1"
      src="@/assets/img/information-outline.svg"
      alt="SVG Image"
    />
    <template #tooltip>
      <slot name="tooltip">
        <span v-html="tooltip"></span>
      </slot>
    </template>
  </TooltipV2>
</label>
