<template>
  <Form
    v-if="defaultValues"
    class="w-full"
    :defaultValues="defaultValues"
    v-bind="$attrs"
    :before="handleSubmitBefore"
    v-on="$listeners"
  >
    <template #default="{ values }">
      <div class="grid grid-cols-2 gap-x-2">
        <Input
          id="extension-package-name"
          v-model="values.name"
          label="*Enter package name"
          :rules="[rules.required, rules.name]"
          appendClass="app-g-input-v2"
          :disabled="!!defaultEditValue"
        />
        <Input
          id="extension-package-display-name"
          v-model="values.displayName"
          label="*Display name"
          :rules="[rules.required]"
          appendClass="app-g-input-v2"
        />
        <v-textarea
          id="extension-package-description"
          v-model="values.description"
          class="col-span-2 mt-1 app-g-text-area-v2"
          outlined
          label="Enter description of this package"
        ></v-textarea>

        <Checkbox
          id="extension-package-type"
          v-model="values.type"
          label="ai-extension"
          true-value="ai-extension"
          false-value=""
        />
      </div>
      <div class="mt-3 mb-3 text-big-label">Selected Extensions</div>

      <RDECatalogTable
        :value="selectedExtensions"
        show-select
        :headers="headers"
        :items="sortedExtensions"
        item-key="customId"
        item-value="customId"
        :items-per-page="5"
        @input="selectItems"
      >
      </RDECatalogTable>

      <div class="flex justify-end gap-x-2 mt-4">
        <Button :size="'md'" @click="closePopup">Cancel</Button>
        <Button :type="'submit'" color="primary" :size="'md'" text fill>
          Save
        </Button>
      </div>
    </template>
  </Form>
</template>

<script>
import Input from "@/components/atoms/Input/Input.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { RulesMixin } from "@/mixins/RulesMixin";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import {
  requestExtensionPackages,
  requestExtensions,
} from "@/service/apis/workspaceApis";
import Checkbox from "@/components/atoms/Checkbox/Checkbox.vue";

export default {
  props: ["closePopup", "reloadData", "defaultEditValue"],
  components: { Input, RDECatalogTable, Checkbox },
  mixins: [RulesMixin],
  data: () => ({
    headers: [
      {
        text: "Organization",
        value: "organization",
        width: "20%",
      },
      { text: "Name", value: "name", width: "25%" },
      { text: "Version", value: "version", width: "20%" },
      { text: "Size", value: "size", width: "20%" },
    ],
    extensionOptions: [],
    defaultValues: null,
    selectedExtensions: [],
  }),
  computed: {
    sortedExtensions() {
      return this.selectedExtensions.concat(
        this.extensionOptions.filter(
          (i) =>
            !this.selectedExtensions.length ||
            this.selectedExtensions.every((s) => s.customId !== i.customId),
        ),
      );
    },
  },
  watch: {
    defaultEditValue: {
      handler() {
        if (this.defaultEditValue) {
          const currentExtensions = (
            this.defaultEditValue.extensions || []
          ).map((item) => ({
            ...item,
            customId: `${item.name}-${item.version}`,
          }));

          this.defaultValues = {
            ...this.defaultEditValue,
            extensions: currentExtensions,
          };
          this.selectedExtensions = currentExtensions;
        } else {
          this.defaultValues = { extensions: [] };
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectItems(selectedItems) {
      this.$nextTick().then(() => {
        this.selectedExtensions = selectedItems;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async handleSubmitBefore(values) {
      if (!this.selectedExtensions?.length) {
        CommonUIControl.ShowWarningToast(
          "You must select at least one extension.",
          5000,
        );
        return false;
      }

      CommonUIControl.ShowUIProgress();

      const payload = {
        name: values.name,
        displayName: values.displayName,
        description: values.description,
        type: values.type,
        extensions: this.selectedExtensions.map((item) => ({
          organization: item.organization,
          name: item.name,
          version: item.version,
        })),
      };
      CommonUIControl.ShowUIProgress();

      await requestExtensionPackages({
        method: this.defaultEditValue ? "put" : "post",
        data: payload,
      })
        .then(() => {
          CommonUIControl.ShowSuccessToast(
            `Extension package ${
              this.defaultEditValue ? "updated" : "created"
            } successfully.`,
            5000,
          );
        })
        .catch((e) => {
          CommonUIControl.ShowErrorToast(e.response.data.message, 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
      setTimeout(() => {
        this.closePopup();
        this.reloadData();
      }, 100);

      return false;
    },
  },
  mounted() {
    CommonUIControl.ShowUIProgress();
    requestExtensions()
      .then((res) => {
        this.extensionOptions = (res?.data || []).map((item) => ({
          ...item,
          customId: `${item.name}-${item.version}`,
        }));
      })
      .finally(() => {
        CommonUIControl.HideUIProgress();
      });
  },
};
</script>

<style lang="scss" scoped>
.text-big-label {
  color: #131316;
  font-weight: 500;
  font-size: 14px;
}
</style>
