<template>
  <div class="p-0 m-0">
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Installation Packages</p>
      <div class="grid grid-cols-4 mt-0">
        <v-autocomplete
          id="rde-input-java-version"
          v-model="values.installPackages.javaVersion"
          :items="javaVersions"
          label="Java Version"
          small-chips
          placeholder="Select Java Version"
          class="app-g-autocomplete-v2"
          outlined
          dense
        />
        <v-autocomplete
          id="rde-input-node-version"
          v-model="values.installPackages.nodeVersion"
          :items="nodeVersions"
          label="Node Version"
          small-chips
          placeholder="Select Node Version"
          class="app-g-autocomplete-v2"
          outlined
          dense
        />
        <v-autocomplete
          id="rde-input-apt-packages"
          v-model="values.installPackages.aptPackages"
          :items="linuxPackages"
          label="Linux Packages"
          small-chips
          placeholder="Select Linux Packages"
          class="app-g-autocomplete-v2"
          clearable
          deletable-chips
          outlined
          dense
          multiple
        />
        <v-autocomplete
          id="rde-input-pip-packages"
          v-model="values.installPackages.pipPackages"
          :items="pythonPackages"
          label="Python Packages"
          small-chips
          placeholder="Select Python Packages"
          class="app-g-autocomplete-v2"
          clearable
          deletable-chips
          outlined
          dense
          multiple
        />
      </div>
    </div>
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Pre-start scripts</p>
      <v-autocomplete
        id="rde-input-preflightConfig-name"
        v-model="values.installPackages.preflightConfig.name"
        :items="preflightConfigResourceNames"
        small-chips
        placeholder="Resource Name"
        label="Select Resource Name"
        class="app-g-autocomplete-v2 pt-2 v-auto-help-message"
        outlined
        dense
        :messages="
          values.installPackages.preflightConfig.configType
            ? `Type: ${values.installPackages.preflightConfig.configType}`
            : ''
        "
        @change="onChangePreflightResourceName"
      />
    </div>
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">File and Environment Value Mount</p>
      <FileEnvironment
        :mountedConfigList="values.installPackages.mountedConfigList"
        :installPackages="installPackages"
        :resourceNames="resourceNames"
        @change="setValue('installPackages.mountedConfigList', $event)"
      />
    </div>

    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Shared Storage</p>
      <StorageShare
        :mountedFSList="values.installPackages.mountedFSList"
        :installPackages="installPackages"
        :validate="validate"
        @change="setValue('installPackages.mountedFSList', $event)"
      />
    </div>
  </div>
</template>

<script>
import { RulesMixin } from "@/mixins/RulesMixin";
import { compact, flattenDeep, flatten, uniq } from "lodash";
export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
    installPackages: { type: Object, default: () => ({}) },
    validate: { type: Function, default: () => undefined },
  },
  mixins: [RulesMixin],
  data: () => ({
    javaVersions: [],
    nodeVersions: [],
    linuxPackages: [],
    pythonPackages: [],
    basePreflightConfigResourceNames: [],
    preflightConfigResourceNames: [],
    fileEnvMountConfigList: [],
    resourceNames: [],
  }),
  watch: {
    installPackages: {
      handler() {
        this.javaVersions = this.installPackages?.javaVersions || [];
        this.nodeVersions = this.installPackages?.nodeVersions || [];
        this.pythonPackages = this.installPackages?.pythonPackages || [];
        this.linuxPackages = this.installPackages?.linuxPackages || [];

        this.basePreflightConfigResourceNames = compact(
          uniq(
            flatten(
              Object.values(this.installPackages?.preflightScripts || {}),
            ),
          ),
        );
        this.preflightConfigResourceNames =
          this.basePreflightConfigResourceNames;

        this.fileEnvMountConfigList = compact(
          uniq(
            flattenDeep(
              Object.values(this.installPackages?.mount || {})?.map(
                (configTypes) => Object.values(configTypes),
              ),
            ),
          ),
        );
        this.resourceNames = this.fileEnvMountConfigList;
      },
      immediate: true,
    },
    "values.installPackages.mountedConfigList": {
      handler(values) {
        this.preflightConfigResourceNames =
          this.basePreflightConfigResourceNames.filter(
            (item) => !values.some((value) => value.name === item),
          );
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onChangePreflightResourceName(value) {
      this.resourceNames = this.fileEnvMountConfigList.filter(
        (item) => item != value,
      );

      if (!value) {
        this.setValue("installPackages.preflightConfig.configType", "");
        return;
      }

      const selectedType = Object.keys(
        this.installPackages?.preflightScripts || {},
      ).find((key) => {
        return this.installPackages?.preflightScripts?.[key]?.includes(value);
      });

      this.setValue(
        "installPackages.preflightConfig.configType",
        selectedType || "",
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.label-mini-section {
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;
}
</style>
