
<div>
  <div class="pt-1 grid grid-cols-[120px,1fr] gap-2 text-gray-600 text-sm">
    <div>KIND</div>
    <div>{{ kind }}</div>
    <div>NAME</div>
    <div>{{ name }}</div>
    <div>DISPLAY NAME</div>
    <div>{{ displayName || "" }}</div>
    <div>NAMESPACE</div>
    <div>{{ namespace }}</div>
    <div>CREATED_AT</div>
    <div>{{ createdAt }}</div>
  </div>
  <Collapse
    v-model="manifestExpand"
    title="MANIFEST"
    class="mt-2"
    :outlined="true"
    :titlePadding="false"
    :contentPadding="false"
  >
    <div class="mt-2 px-4 relative">
      <MonacoEditor
        language="yaml"
        :value="manifestString"
        :readOnly="true"
        :disabledInteraction="true"
        :hideDetails="true"
        height="50vh"
      />
    </div>
  </Collapse>
</div>
