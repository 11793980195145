<template>
  <label v-if="label">
    {{ label }} <span v-if="isRequired" class="requiredSyntax">*</span>
    <TooltipV2 v-if="hasTooltipSlot" contentClass="inline">
      <img
        class="inline w-4 h-4 ml-1 mb-1"
        src="@/assets/img/information-outline.svg"
        alt="SVG Image"
      />
      <template #tooltip>
        <slot name="tooltip">
          <span v-html="tooltip"></span>
        </slot>
      </template>
    </TooltipV2>
  </label>
</template>

<script>
export default {
  props: {
    /** If **true** displays red asterisk after the label. */
    isRequired: { type: Boolean, default: false },
    /** The label text. */
    label: { type: String, default: "" },
    /** Tooltip title. */
    tooltip: { type: String, default: "" },
  },
  computed: {
    hasTooltipSlot() {
      return this.$slots.tooltip || !!this.tooltip;
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #7f84a1;
}
.requiredSyntax {
  color: var(--text-error);
  font-size: var(--font-02);
  font-weight: 500;
}
</style>
