import { imageIconMap } from "@/components/atoms/ImageIcon/ImageIcon.vue";
import {
  mappingNotificationIconStatus,
  mappingNotificationStatus,
  mappingNotificationStatusByEventCodes,
  notificationCategoryIcons,
  notificationIcons,
  notificationRoutes,
  notificationStatus,
} from "@/components/notifications/constants";

export const formatNotification = (notification) => {
  const { eventCode = "" } = notification || {};

  const matchedIcons = Object.entries(notificationIcons).find(
    ([, groupTypes]) => groupTypes.includes(eventCode),
  );
  const [icon] = matchedIcons || [];

  const arrStr = eventCode.toLowerCase().split("_");
  let status = arrStr[arrStr.length - 1];

  if (!Object.values(notificationStatus).includes(status)) {
    const [matchedStatus] =
      Object.entries(mappingNotificationStatusByEventCodes).find(
        ([, statuses]) => statuses.includes(eventCode),
      ) || [];

    status = matchedStatus;
  }

  return {
    ...notification,
    icon,
    status: mappingNotificationStatus[status],
    iconStatus: mappingNotificationIconStatus[status],
  };
};

export const calculateGroupSettings = (settings) => {
  const groupSettingsByType = settings.reduce((acc, setting) => {
    const {
      label,
      description,
      settings: groupSettings,
    } = acc[setting.eventType] || {};

    return {
      ...acc,
      [setting.eventType]: {
        label: label || setting.eventTypeName,
        description: description || setting.eventTypeDescription,
        settings: [...(groupSettings || []), setting],
      },
    };
  }, {});

  return Object.entries(groupSettingsByType).reduce(
    (acc, [groupKey, groupData]) => {
      return [
        ...acc,
        {
          ...groupData,
          icon: notificationCategoryIcons[groupKey],
          key: groupKey,
          panel: [0],
        },
      ];
    },
    [],
  );
};

export const getNotificationPublicIconUrl = (iconStatus) => {
  return window.location.origin + imageIconMap[iconStatus]?.();
};

export const getNotificationRouteUrl = (notification) => {
  const { eventCode } = notification;

  const matchedRoute = notificationRoutes.find(({ eventCodes }) =>
    eventCodes.includes(eventCode),
  );

  return matchedRoute ? matchedRoute.routeUrl(notification) : "";
};
