<template>
  <div class="storage-type-share-panel">
    <div
      v-for="(item, index) in mountedFSList"
      :key="`storage-type-${index}`"
      class="grid grid-cols-4 gap-x-2"
    >
      <v-autocomplete
        :id="`rde-input-storage-share-name-${index}`"
        v-model="item.pvcName"
        :items="genOptions(item.pvcName)"
        label="* File Storage (PVC)"
        small-chips
        placeholder="Select File Storage (PVC)"
        class="app-g-autocomplete-v2"
        outlined
        dense
        :rules="[rules.required]"
      />
      <Input
        :id="`rde-input-storage-share-aliasName-${index}`"
        v-model="item.aliasName"
        placeholder="Enter Directory Name"
        :rules="[rules.required, rules.name, validateDuplicate]"
        label="* Directory Name"
        appendClass="input-text-field-v2 text-details"
      />
      <div class="flex w-3">
        <v-icon class="h-6 mt-1" @click="handleRemoveClick(index)">
          mdi-close
        </v-icon>
      </div>
    </div>
    <Button class="add-button" size="md" @click="handleAddClick">
      <v-icon>mdi-plus</v-icon>
      ADD Storage Share
    </Button>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button/Button.vue";
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  props: {
    mountedFSList: {
      type: Array,
      default: () => [],
    },
    installPackages: { type: Object, default: () => ({}) },
    validate: { type: Function, default: () => undefined },
  },
  components: { Button },
  mixins: [RulesMixin],
  emits: ["change"],
  data: function () {
    return {
      pvcNameOptions: [],
    };
  },
  watch: {
    installPackages: {
      handler() {
        this.pvcNameOptions = this.installPackages?.share?.volume || [];
      },
      immediate: true,
    },
  },
  methods: {
    genOptions(value) {
      return this.pvcNameOptions?.filter(
        (option) =>
          option === value ||
          !this.mountedFSList?.length ||
          this.mountedFSList.every((cur) => cur.pvcName !== option),
      );
    },
    validateDuplicate(value) {
      return value &&
        this.mountedFSList &&
        this.mountedFSList?.filter((i) => i?.aliasName === value)?.length > 1
        ? `Directory Name is duplicated!`
        : true;
    },
    handleAddClick() {
      const newItems = [
        {
          pvcName: "",
          aliasName: "",
        },
        ...this.mountedFSList,
      ];
      this.$emit("change", newItems);
      setTimeout(() => {
        this.validate();
      }, 200);
    },
    handleRemoveClick(index) {
      const newItems = this.mountedFSList.filter((_, i) => i !== index);
      this.$emit("change", newItems);
      setTimeout(() => {
        this.validate();
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.storage-type-share-panel {
  padding: unset;
  margin: unset;
  margin-top: 10px;
  border: none;
  .add-button {
    border: none;
    background: #f5f5f5;
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
