import {
  calculateGroupSettings,
  formatNotification,
} from "@/helper/notificationHelper";

export default {
  namespace: true,
  state: () => ({
    notifications: [],
    totalPages: 0,
    totalUnreadCount: 0,
    settings: [], // Notification settings
    groupSettings: [], // Notification group settings
  }),
  getters: {
    unreadNotifications(state) {
      return state.notifications.filter((item) => !item.isRead);
    },
  },
  mutations: {
    setTotalUnreadCount(state, totalUnreadCount) {
      state.totalUnreadCount = totalUnreadCount || 0;
    },
    incrementTotalUnreadCount(state) {
      state.totalUnreadCount++;
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },
    setNotificationSettings(state, settings) {
      state.settings = settings;
      state.groupSettings = calculateGroupSettings(settings);
    },
    setNotifications(state, notifications) {
      state.notifications = notifications.map(formatNotification);
    },
    addNotifications(state, notifications) {
      state.notifications = [
        ...state.notifications,
        ...notifications.map(formatNotification),
      ];
    },
    receiveNotification(state, payload) {
      state.notifications = [payload, ...state.notifications];

      this.commit("incrementTotalUnreadCount");
    },
    readNotification(state, notificationId) {
      // If notificationId is undefined that means mark all as read
      let isUnreadNotification = false;

      state.notifications = state.notifications.map((item) => {
        const { id, isRead } = item;

        if (id === notificationId && !isRead) isUnreadNotification = true;

        return {
          ...item,
          isRead: !notificationId || id === notificationId || isRead,
        };
      });

      if (!notificationId) state.totalUnreadCount = 0;
      else if (isUnreadNotification) state.totalUnreadCount--;
    },
    resetNotifications(state) {
      state.notifications = [];
      state.totalPages = 0;
    },
  },
};
