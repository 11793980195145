import { generatePathUrl } from "@/helper/utils";

export const notificationEventCodes = {
  DEPLOY_SUCCESS: "DEPLOY_SUCCESS",
  DEPLOY_FAILED: "DEPLOY_FAILED",
  BUILD_SUCCESS: "BUILD_SUCCESS",
  BUILD_FAILED: "BUILD_FAILED",
  INGRESS_BUILD_SUCCESS: "INGRESS_DEPLOY_SUCCESS",
  INGRESS_BUILD_FAILED: "INGRESS_DEPLOY_FAILED",
  STORAGE_DEPLOY_SUCCESS: "STORAGE_DEPLOY_SUCCESS",
  STORAGE_DEPLOY_FAILED: "STORAGE_DEPLOY_FAILED",
  PIPELINE_SCHEDULE_SUCCESS: "SCHEDULE_RUN_AUTOMATED_PIPELINE_SCHEDULE_SUCCESS",
  PIPELINE_SCHEDULE_FAILED: "SCHEDULE_RUN_AUTOMATED_PIPELINE_SCHEDULE_FAILED",
  SETUP_WEBHOOK_SUCCESS: "SETUP_WEBHOOK_SUCCESS",
  SETUP_WEBHOOK_FAILED: "SETUP_WEBHOOK_FAILED",
  RDE_RUNNING: "RDE_RUNNING",
  RDE_STOP: "RDE_STOP",
  RDE_FAILED: "RDE_FAILED",
  RDE_SHUTDOWN: "RDE_SHUTDOWN",
  ADD_ADMIN_TO_PROJECT: "ADD_ADMIN_TO_PROJECT",
  REMOVE_ADMIN_FROM_PROJECT: "REMOVE_ADMIN_FROM_PROJECT",
  ADD_USER_TO_PROJECT: "ADD_USER_TO_PROJECT",
  REMOVE_USER_FROM_PROJECT: "REMOVE_USER_FROM_PROJECT",
  ROLE_MODIFICATIONS: "ROLE_MODIFICATIONS",
};

export const notificationStatus = {
  SUCCESS: "success",
  FAILED: "failed",
};

export const notificationIcons = {
  pipeline: [
    notificationEventCodes.BUILD_SUCCESS,
    notificationEventCodes.BUILD_FAILED,
    notificationEventCodes.DEPLOY_SUCCESS,
    notificationEventCodes.DEPLOY_FAILED,
  ],
  addUser: [
    notificationEventCodes.ADD_USER_TO_PROJECT,
    notificationEventCodes.REMOVE_USER_FROM_PROJECT,
  ],
  userShield: [
    notificationEventCodes.ADD_ADMIN_TO_PROJECT,
    notificationEventCodes.REMOVE_ADMIN_FROM_PROJECT,
  ],
  networkAlt: [
    notificationEventCodes.STORAGE_DEPLOY_SUCCESS,
    notificationEventCodes.STORAGE_DEPLOY_FAILED,
  ],
  userSettings: [notificationEventCodes.ROLE_MODIFICATIONS],
  reload: [
    notificationEventCodes.PIPELINE_SCHEDULE_SUCCESS,
    notificationEventCodes.PIPELINE_SCHEDULE_FAILED,
  ],
  pieChartAlt: [
    notificationEventCodes.RDE_RUNNING,
    notificationEventCodes.RDE_STOP,
    notificationEventCodes.RDE_FAILED,
    notificationEventCodes.RDE_SHUTDOWN,
  ],
  vector: [
    notificationEventCodes.SETUP_WEBHOOK_SUCCESS,
    notificationEventCodes.SETUP_WEBHOOK_FAILED,
  ],
  enter: [
    notificationEventCodes.INGRESS_BUILD_SUCCESS,
    notificationEventCodes.INGRESS_BUILD_FAILED,
  ],
};

// Use for event code that does not end with a status. (SUCCESS, FAILED)
export const mappingNotificationStatusByEventCodes = {
  [notificationStatus.SUCCESS]: [notificationEventCodes.RDE_RUNNING],
  [notificationStatus.FAILED]: [],
};

export const mappingNotificationStatus = {
  [notificationStatus.SUCCESS]: "success",
  [notificationStatus.FAILED]: "error",
};

export const mappingNotificationIconStatus = {
  [notificationStatus.SUCCESS]: "mdi-check",
  [notificationStatus.FAILED]: "mdi-close",
};

export const notificationCategoryIcons = {
  BUILD_AND_DEPLOYMENT: "pipe",
  USER_PERMISSION: "userPermissions",
  RDE: "pieChartAlt",
};

export const notificationRoutes = [
  {
    eventCodes: [
      notificationEventCodes.BUILD_SUCCESS,
      notificationEventCodes.BUILD_FAILED,
      notificationEventCodes.DEPLOY_SUCCESS,
      notificationEventCodes.DEPLOY_FAILED,
      notificationEventCodes.STORAGE_DEPLOY_SUCCESS,
      notificationEventCodes.STORAGE_DEPLOY_FAILED,
      notificationEventCodes.INGRESS_BUILD_SUCCESS,
      notificationEventCodes.INGRESS_BUILD_FAILED,
    ],
    routeUrl: ({ projectId, profileId, microserviceId }) =>
      generatePathUrl({
        projects: projectId,
        profiles: profileId,
        microservices: microserviceId, // excludes (STORAGE_DEPLOY, INGRESS_BUILD)
      }),
  },
  {
    eventCodes: [
      notificationEventCodes.RDE_RUNNING,
      notificationEventCodes.RDE_STOP,
      notificationEventCodes.RDE_FAILED,
      notificationEventCodes.RDE_SHUTDOWN,
    ],
    routeUrl: () => "/my-rde",
  },
  {
    eventCodes: [notificationEventCodes.ROLE_MODIFICATIONS],
    routeUrl: () => "/account/profile",
  },
  {
    eventCodes: [
      notificationEventCodes.PIPELINE_SCHEDULE_SUCCESS,
      notificationEventCodes.PIPELINE_SCHEDULE_FAILED,
      notificationEventCodes.SETUP_WEBHOOK_SUCCESS,
      notificationEventCodes.SETUP_WEBHOOK_FAILED,
    ],
    routeUrl: (notification) => {
      const {
        projectId,
        profileId,
        microserviceId,
        data: jsonData = "",
      } = notification;

      let pipelineId;
      try {
        const parsedData = JSON.parse(jsonData || "{}");
        pipelineId = parsedData?.pipelineId;
      } catch (e) {
        console.log("Can not parse Notification's data :>> ", e);
      }

      return generatePathUrl({
        projects: projectId,
        profiles: profileId,
        microservices: microserviceId,
        Pipeline: pipelineId,
      });
    },
  },
  {
    eventCodes: [
      notificationEventCodes.ADD_USER_TO_PROJECT,
      notificationEventCodes.ADD_ADMIN_TO_PROJECT,
    ],
    routeUrl: ({ projectId }) => `/projects/${projectId}`,
  },
  {
    eventCodes: [
      notificationEventCodes.REMOVE_USER_FROM_PROJECT,
      notificationEventCodes.REMOVE_ADMIN_FROM_PROJECT,
    ],
    routeUrl: () => "/Setup/projects", // Project List
  },
];

export const notificationSocketChannels = {
  NOTIFICATION: "/user/queue/messages",
};
