<template>
  <v-dialog v-model="show" persistent :max-width="maxWidth" max-height="91vh">
    <v-card class="p-3 pl-5 dialog-content-card">
      <template v-if="!!title">
        <v-card-title class="header">
          <span class="dialog-title">{{ title }}</span>
          <v-spacer />
          <v-btn icon color="grey" @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <v-container class="p-0 container">
        <slot>
          <component
            :is="content"
            v-bind="contentProps"
            :closePopup="closePopup"
          />
        </slot>
      </v-container>
      <v-card-actions
        v-if="actions && actions.length"
        class="flex justify-end footer"
      >
        <v-btn
          v-for="(action, index) in actions"
          :key="index"
          class="no-uppercase"
          :color="action.color"
          :fill="action.fill"
          :outlined="action.outlined"
          :type="action.type"
          :disabled="action.disabled"
          @click="() => action.onClick && action.onClick(closePopup)"
        >
          {{ action.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  props: {
    /** If **true** the dialog will be shown. */
    show: {
      type: Boolean,
      default: false,
    },
    /** Dialog title. */
    title: {
      type: String,
      default: "",
    },
    /** Dialog max width. */
    maxWidth: {
      type: [String, Number],
      default: "700px",
    },
    /** Dialog width. */
    width: {
      type: [String, Number],
      default: "700px",
    },
    /** Customize dialog actions. */
    actions: {
      type: Array,
      default: () => [],
    },
    /** Use for customize dialog content. */
    content: {
      type: [Object, String],
    },
    /** Props of component was passed by **content**. */
    contentProps: {
      type: Object,
    },
  },
  methods: {
    closePopup() {
      CommonUIControl.HideModalDialogContentV2();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content-card {
  display: flex;
  flex-direction: column;
  max-height: 91vh;
}
.header,
.footer {
  flex-shrink: 0;
}
.container {
  flex: 1;
  overflow-y: auto;
}
.dialog-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
}
</style>
