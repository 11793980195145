import Vue from "vue";
import Router from "vue-router";
import AuthGuard from "@/components/common/AuthGuard/AuthGuard";
import accountRoutes from "./accountRoutes";
import microserviceRoutes from "./microserviceRoutes";
import ingressRoutes from "./ingressRoutes";
import pipelineRoutes from "./pipelineRoutes";
import topologyRoutes from "./topologyRoutes";
import operationRoutes from "./operationRoutes";
import todoRoutes from "./todoRoutes";
import trafficManagementRoutes from "./trafficManagementRoutes";
import profileRoutes from "./profileRoutes";
import projectRoutes from "./projectRoutes";
import addonServiceRoutes from "./addonServiceRoutes";
import userRoutes from "./userRoutes";
import groupRoutes from "./groupRoutes";
import clusterRoutes from "./clusterRoutes";
import variableRoutes from "./variableRoutes";
import dashboardRoutes from "./dashboardRoutes";
import newWorkspaceRoutes from "./newWorkspaceRoutes";
import remoteEnvironmentRoutes from "./remoteEnvironmentRoutes";
import notificationRoutes from "./notificationRoutes";
import myRdeRoutes from "./myRdeRoutes";

Vue.use(Router);

// # 동일 url 변경 에러 차단
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

/** 경로 정의 */
export const routes = [
  {
    path: "/",
    component: AuthGuard,
    children: [
      {
        path: "/",
        component: () => import("../pages/MainPage.vue"),
        children: [
          {
            path: "/",
            component: () => import("../pages/HomePage.vue"),
          },
          ...accountRoutes,
          ...profileRoutes,
          ...microserviceRoutes,
          ...ingressRoutes,
          ...topologyRoutes,
          ...pipelineRoutes,
          ...trafficManagementRoutes,
          ...todoRoutes,
          ...projectRoutes,
          ...addonServiceRoutes,
          ...userRoutes,
          ...groupRoutes,
          ...clusterRoutes,
          ...variableRoutes,
          ...operationRoutes,
          ...dashboardRoutes,
          ...newWorkspaceRoutes,
          ...remoteEnvironmentRoutes,
          ...notificationRoutes,
          ...myRdeRoutes,
          {
            path: "*",
            redirect: "/",
          },
        ],
      },
    ],
  },
];

/** 라우터 */
const router = new Router({
  mode: "history",
  base: "/main/modernframework",
  routes,
});

export default router;
