
<v-dialog v-model="show" persistent scrollable :max-width="maxWidth">
  <v-card class="p-4">
    <template v-if="!!title">
      <v-card-title>
        <v-icon color="warning">mdi-alert-circle-outline </v-icon>
        <span class="dialog-title">{{ title }}</span>
        <v-spacer />
        <v-btn icon color="grey" @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </template>
    <v-card-text class="pl-10 pt-0">
      <span v-if="message" class="dialog-message"> {{ message }} </span>
      <component :is="content" v-else-if="!!content" v-bind="contentProps" />
    </v-card-text>
    <v-card-actions class="flex justify-end">
      <fragment v-if="actions">
        <v-btn
          v-for="(action, index) in actions"
          :key="index"
          class="no-uppercase"
          :color="action.color"
          :fill="action.fill"
          @click="() => action.onClick(closePopup)"
        >
          {{ action.text }}
        </v-btn>
      </fragment>
      <fragment v-else>
        <v-btn
          v-if="cancelText"
          class="no-uppercase"
          outlined
          color="primary"
          @click="closePopup"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn class="no-uppercase" fill color="primary" @click="onClickYes">
          {{ yesText }}
        </v-btn>
      </fragment>
    </v-card-actions>
  </v-card>
</v-dialog>
