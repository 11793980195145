
<v-select
  :id="id"
  v-model="infraData"
  :items="infraBaselineList"
  :item-text="showLabel"
  item-value="id"
  attach
  class="app-g-select-v2"
  clearable
  outlined
  dense
  :label="required ? '* Resource Classes' : 'Resource Classes'"
  :rules="[validateRequired]"
  :multiple="multiple"
  @input="onChange"
>
  <template v-slot:item="data">
    <v-list-item-content>
      <v-list-item-title>
        {{ data.item.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ infraSubtitleFormat(data.item) }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </template>
  <template #selection="{ attrs, item, parent, select, selected }">
    <v-chip
      color="primary"
      close-icon="mdi-close"
      v-bind="attrs"
      :input-value="selected"
      :close="multiple"
      small
      @click="select"
      @click:close="parent.selectItem(item)"
    >
      {{ item.name }}
    </v-chip>
  </template>
</v-select>
