import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client/dist/sockjs";

const { VUE_APP_NOTIFICATION_SOCKET_URL } = process.env;

export class SocketService {
  constructor({ token }) {
    this.token = token;
    this.stompClient = null;
    this.subscriptions = new Map();
  }

  asyncConnect() {
    return new Promise((resolve, reject) => {
      this.stompClient = new Client({
        webSocketFactory: () => new SockJS(VUE_APP_NOTIFICATION_SOCKET_URL),
        debug: console.log,
        connectHeaders: { token: this.token },
        reconnectDelay: 5000,
        onConnect: () => {
          resolve(this.stompClient);

          this.reSubscribeAll();
        },
        onStompError: reject,
        onWebSocketClose: reject,
        onWebSocketError: reject,
      });

      this.activate();
    });
  }

  activate() {
    this.stompClient.activate();
  }

  deactivate() {
    this.stompClient.deactivate();
  }

  subscribe(channel, callback) {
    if (!this.stompClient.connected) {
      console.log("STOMP is not connected yet.");
      return;
    }

    const subscription = this.stompClient.subscribe(channel, callback);
    this.subscriptions.set(channel, { subscription, callback });
  }

  unsubscribe(channel) {
    if (!this.stompClient.connected) {
      console.log("STOMP is not connected yet.");
      return;
    }

    const { subscription } = this.subscriptions.get(channel) || {};

    if (!subscription) {
      return console.log(`No active subscription found for ${channel}`);
    }

    subscription.unsubscribe();
    this.subscriptions.delete(channel);
  }

  reSubscribeAll() {
    if (!this.subscriptions.size) return;

    console.log("Re-subscribing to all channels...");
    this.subscriptions.forEach(({ callback }, channel) => {
      this.subscribe(channel, callback);
    });
  }

  disconnect() {
    this.subscriptions.forEach(({ subscription }) =>
      subscription.unsubscribe(),
    );

    this.subscriptions.clear();
    this.stompClient.deactivate();
    console.log("Disconnected from STOMP.");
  }
}
