
<v-dialog v-model="show" persistent :max-width="maxWidth" max-height="91vh">
  <v-card class="p-3 pl-5 dialog-content-card">
    <template v-if="!!title">
      <v-card-title class="header">
        <span class="dialog-title">{{ title }}</span>
        <v-spacer />
        <v-btn icon color="grey" @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </template>
    <v-container class="p-0 container">
      <slot>
        <component
          :is="content"
          v-bind="contentProps"
          :closePopup="closePopup"
        />
      </slot>
    </v-container>
    <v-card-actions
      v-if="actions && actions.length"
      class="flex justify-end footer"
    >
      <v-btn
        v-for="(action, index) in actions"
        :key="index"
        class="no-uppercase"
        :color="action.color"
        :fill="action.fill"
        :outlined="action.outlined"
        :type="action.type"
        :disabled="action.disabled"
        @click="() => action.onClick && action.onClick(closePopup)"
      >
        {{ action.text }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
