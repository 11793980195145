<template>
  <div ref="root">
    <div class="flex justify-between items-end pr-2">
      <v-btn-toggle v-model="viewType" tile color="deep-purple accent-3" group>
        <v-btn v-if="serviceTypes.length > 1" value=""> All </v-btn>
        <v-btn v-if="serviceTypes.includes('vscode')" value="vscodeserver">
          <SvgIcon iconName="vscode" size="sm" color="#000" class="mr-1" />
        </v-btn>
        <v-btn v-if="serviceTypes.includes('webssh')" value="sshserver">
          <SvgIcon iconName="ssh" size="md" color="#000" class="mr-1" />
        </v-btn>
        <v-btn v-if="serviceTypes.includes('notebook')" value="jupyter">
          <SvgIcon iconName="jupyter" size="md" color="#000" class="mr-1" />
        </v-btn>
      </v-btn-toggle>
      <IconButton
        iconName="mdi-clipboard-text-multiple-outline"
        tooltip="클립보드 복사"
        tooltipPosition="top"
        @click="handleCopyClipBoard"
      />
    </div>
    <MonacoEditor
      ref="monacoEditor"
      class="mt-2"
      language="yaml"
      :value="content"
      :readOnly="true"
      :disabledInteraction="true"
      :hideDetails="true"
      :height="height"
    />
  </div>
</template>

<script>
import utils from "@/helper/utils";
import IconButton from "@/components/atoms/IconButton/IconButton.vue";
import MonacoEditor from "@/components/compositions/MonacoEditor/MonacoEditor.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { getLogsRDEState } from "@/service/apis/workspaceApis";

const SERVICE_TYPE = {
  vscode: "vscodeserver",
  webssh: "sshserver",
  notebook: "jupyter",
};

export default {
  props: {
    rdeId: {
      type: String,
    },
    serviceTypes: {
      type: Array,
      default: () => [],
    },
    reload: {
      type: Number,
      default: 0,
    },
  },
  components: { MonacoEditor, IconButton },
  data: () => ({
    logs: [],
    height: "70vh",
    content: "",
    viewType: "",
  }),

  watch: {
    rdeId: {
      immediate: true,
      handler(rdeId) {
        if (!rdeId) {
          this.logs = [];
          return;
        }
        this.loadLogs();
      },
    },
    viewType: {
      immediate: true,
      handler() {
        this.loadLogs();
      },
    },
    reload: {
      immediate: true,
      handler(value) {
        if (value) this.loadLogs();
      },
    },
  },
  methods: {
    handleCopyClipBoard() {
      utils.copyToClipboard(this.content);
      CommonUIControl.ShowSuccessToast("클립보드에 복사를 완료 하였습니다.");
    },
    async loadLogs() {
      if (this.rdeId && this.serviceTypes.length) {
        if (this.serviceTypes?.length === 1) {
          this.viewType = SERVICE_TYPE[this.serviceTypes[0]] || "";
        }
        CommonUIControl.ShowUIProgress();
        getLogsRDEState(this.rdeId, this.viewType)
          .then((result) => {
            this.content = result?.data?.data || "";

            this.$nextTick(() => {
              const editor = this.$refs.monacoEditor.editor;
              if (editor) {
                const lineCount = editor.getModel().getLineCount();
                editor.revealLine(lineCount);
              }
            });
          })
          .finally(() => {
            CommonUIControl.HideUIProgress();
          });
      }
    },
  },
};
</script>

<style lang="scss"></style>
