
<v-switch
  :id="id"
  v-model="checked"
  class="atoms__sw"
  :class="[appendClass]"
  :value="true"
  v-bind="$attrs"
  :color="color"
  :disabled="disabled"
  v-on="$listeners"
  @change="handleChange"
></v-switch>
