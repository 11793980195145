<template>
  <v-tooltip
    top
    right
    color="primary"
    :disabled="disabled || (!text && !$slots.tooltip)"
  >
    <template v-slot:activator="{ on, attrs }">
      <div :class="contentClass" class="w-fit" v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>
    <!-- <span>{{ text }}</span> -->
    <slot name="tooltip">
      <div
        style="max-width: 500px; text-wrap: wrap"
        class="break-all line-clamp-20"
      >
        {{ text }}
      </div>
    </slot>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    /** The tooltip description. */
    text: {
      type: [String, Number],
      default: "",
    },
    /** If **true** the tooltip will not display. */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Customization class for content. */
    contentClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
