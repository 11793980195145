<template>
  <div
    class="compositions__collapse"
    :class="[outlined && 'outlined', sw && 'expand', className]"
    tabindex="0"
  >
    <!-- title -->
    <div
      class="collapse__title"
      :class="[
        titlePadding === false ? '' : 'p-4',
        titleClass,
        sw ? expandedTitleClass : collapsedTitleClass,
        hideIcon ? '' : 'flex justify-between items-center',
      ]"
      :title="title"
      @click.stop="handleToggleClick"
    >
      <div name="title" :class="darkMode ? 'text-white' : ''">
        {{ $props.title }}
        <slot name="title" :sw="sw"></slot>
      </div>
      <v-icon
        v-if="!hideIcon"
        role="button"
        :color="iconColor"
        :dark="darkMode"
        class="text-text-secondary"
      >
        {{ sw ? "mdi-chevron-up" : "mdi-chevron-down" }}
      </v-icon>
    </div>

    <!-- content -->
    <div
      class="collapse__content border-gray-300"
      :class="[
        outlined && sw ? 'border-t' : '',
        contentPadding === false ? '' : 'px-3',
        animationDisabled === true ? 'animation-disabled' : '',
      ]"
      :style="{
        height:
          (animationDisabled === true && sw === true) ||
          (!animationDisabled && done === true)
            ? 'initial'
            : `${contentHeight}px`,
      }"
      @transitionend="handleContentTransitionEnd"
    >
      <!-- :style="{ height: done === false ? `${contentHeight}px` : 'initial' }" -->
      <div
        ref="contentBodyEl"
        class="content__body"
        :class="{ visibleBody: visibleBody, 'py-4': outlined }"
      >
        <slot :sw="sw" :toggle="toggle" :close="close"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { timeoutFn } from "@/service/topologyHelper";

export default {
  props: {
    /** 타이틀 */
    title: {
      type: String,
      default: "",
    },
    /** true일 경우 외곽선 */
    outlined: {
      type: Boolean,
    },
    /** true일 경우 다크 모드 적용 */
    darkMode: {
      type: Boolean,
    },
    /** true일 경우 컨텐츠 내부 패딩 적용 */
    contentPadding: {
      type: Boolean,
      default: true,
    },
    /** false일 경우 타이틀에 기본 padding을 적용하지 않는다 */
    titlePadding: {
      type: Boolean,
      default: true,
    },
    /** true일 경우 아이콘 숨김 */
    hideIcon: {
      type: Boolean,
    },
    /** true 일 경우 애니메이션 비활성화 */
    animationDisabled: {
      type: Boolean,
    },
    /** 아이콘 색상 */
    iconColor: {
      type: String,
    },
    /** 아이콘 클래스 */
    titleClass: {
      type: String,
    },
    /** 확장 시 타이틀에 적용할 클래스 */
    expandedTitleClass: {
      type: String,
    },
    /** 축소 시 타이틀에 적용할 클래스 */
    collapsedTitleClass: {
      type: String,
    },
    /** value */
    value: {
      type: Boolean,
    },
    className: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    contentHeight: 0,
    sw: false,
    done: false,
    visibleBody: false,
  }),
  computed: {},
  watch: {
    sw() {
      this.calculateHeight();
    },
    value: {
      immediate: true,
      handler(value) {
        this.done = false;
        this.sw = !!value;
      },
    },
  },
  methods: {
    calculateHeight() {
      if (this.sw === true) {
        this.visibleBody = true;
        this.contentHeight = this.$refs.contentBodyEl.scrollHeight;
      } else {
        this.contentHeight = 0;
      }
      this.$emit("input", this.sw);
    },
    /** 애니메이션 종료 콜백 */
    handleContentTransitionEnd(e) {
      e.stopPropagation();
      // console.log(": transition out");
      debounce(() => {
        // console.log(": debounce: ", this.sw);
        if (this.sw === false) {
          this.visibleBody = false;
        } else {
          this.done = true;
        }
      }, 300);
    },
    /** 토글 버튼 클릭 */
    handleToggleClick() {
      this.toggle();
    },
    /** 토글 */
    toggle() {
      if (this.hideIcon) return;

      if (this.sw === true) {
        this.contentHeight = this.$refs.contentBodyEl.clientHeight;
        this.done = false;
      }
      setTimeout(() => {
        this.sw = !this.sw;
      }, 0);
    },
    close() {
      this.contentHeight = 0;
      this.done = false;
      this.sw = false;
      this.$emit("input", false);
    },
  },
  updated() {
    this.calculateHeight();
  },
};

const debounce = timeoutFn("debounce");
</script>

<style lang="scss" scoped>
.compositions__collapse {
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s linear, margin-bottom 0.2s linear !important;
  border-radius: 6px;

  &.outlined.expand:hover {
    border-color: #76a9fa !important;
  }

  .collapse__title {
    width: 100%;
    cursor: pointer;
    @apply font-medium text-base text-text-primary;
  }
  .collapse__content {
    overflow: hidden;
    cursor: unset;
    height: 0px;
    transition: all 0.01s linear;
    &:not(.animation-disabled) {
      transition: all 0.2s linear;
    }
    &.active {
      border: 1px solid;
    }
    .content__body {
      opacity: 0;
      visibility: hidden;
      &.visibleBody {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }

  &.outlined {
    @apply border border-gray-200 rounded;
    & .collapse__title {
      background-color: var(--background-02);
      @apply py-4 px-3 font-normal
        text-sm text-text-primary;
    }
  }
}
</style>
