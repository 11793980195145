
<Form
  v-if="defaultValues"
  ref="imageGroupform"
  class="w-full"
  :defaultValues="defaultValues"
  v-bind="$attrs"
  :before="handleSubmitBefore"
  v-on="$listeners"
>
  <template #default="{ values }">
    <v-autocomplete
      id="image-group-type"
      v-model="values.type"
      label="Select Type"
      itemText="label"
      itemValue="value"
      :items="typeOptions"
      class="app-g-autocomplete-v2 mb-2"
      clearable
      outlined
      dense
      :rules="[rules.required]"
      :disabled="!!defaultEditValue"
      @change="changeType"
    />
    <div class="grid grid-cols-2 gap-x-2">
      <Input
        id="image-group-name"
        v-model="values.name"
        label="*Enter group name"
        :rules="[rules.required]"
        appendClass="app-g-input-v2"
        :disabled="!!defaultEditValue"
      />
      <Input
        id="image-group-description"
        v-model="values.description"
        label="Enter description of this group"
        appendClass="app-g-input-v2 "
      />
    </div>
    <div
      class="grid grid-cols-2 gap-x-2 pt-2 pb-3"
      style="color: #131316; font-weight: 500; font-size: 14px"
    >
      Selected Images
    </div>

    <RDECatalogTable
      v-if="!!values.type"
      :value="selectedImages"
      show-select
      :headers="headers"
      :items="sortedImages"
      item-key="customId"
      item-value="customId"
      :items-per-page="5"
      disable-sort
      @input="selectItems"
    >
    </RDECatalogTable>

    <div class="flex justify-end gap-x-2 mt-4">
      <Button :size="'md'" @click="closePopup">Cancel</Button>
      <Button :type="'submit'" color="primary" :size="'md'" text fill>
        Save
      </Button>
    </div>
  </template>
</Form>
