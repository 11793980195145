<template>
  <div :id="`user-group-select-${groupName}`" class="user-input-rde">
    <h6>{{ groupName }}</h6>
    <RadioGroup
      v-model="multiSelectType"
      :items="[
        {
          label: 'User',
          value: 'user',
        },
        {
          label: 'Group User',
          value: 'group',
        },
      ]"
      @input="handleTypeChange"
    />
    <v-autocomplete
      v-if="multiSelectType === 'user'"
      :id="`user-group-select-dropdown-${groupName}`"
      v-model="values[mapFields]"
      :items="final_user_items"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      label="User"
      :rules="[validateRequired]"
    />

    <v-autocomplete
      v-if="multiSelectType === 'group'"
      :id="`user-group-select-dropdown-${groupName}`"
      v-model="selectedUserGroups"
      :items="user_group_items"
      itemText="name"
      itemValue="members"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      label="Group User"
      :rules="[validateRequired]"
      @change="handleChangeGroup"
    />
  </div>
</template>

<script>
import RadioGroup from "@/components/compositions/RadioGroup/RadioGroup.vue";
import API from "@/service/api";
import { isEqual, compact, uniq } from "lodash";

export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
    projectId: {
      type: [String, Number],
    },
    required: {
      type: Boolean,
      default: true,
    },
    groupName: { type: String, default: "" },
    mapFields: { type: String },
    watchUser: { type: Array, default: () => [] },
    watchGroup: { type: Array, default: () => [] },
    accountInfo: { type: Object, default: () => {} },
  },
  components: { RadioGroup },
  data: () => ({
    multiSelectType: "user",
    selectedUserGroups: [],
    user_items: [],
    origin_user_items: [],
    user_group_items: [],
    origin_user_group_items: [],
  }),
  computed: {
    final_user_items() {
      // this function to help display selected user from group who is not included in user options
      if (
        this.multiSelectType === "user" &&
        this.values[this.mapFields]?.length
      ) {
        return uniq(this.values[this.mapFields].concat(this.user_items || []));
      }
      return this.user_items;
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(v) {
        if (v) {
          this.fetchInitUserData(v);
        }
      },
    },
    "accountInfo.username": {
      immediate: true,
      handler(v) {
        if (v) {
          this.user_items = [v];
          this.origin_user_items = [v];
        }
      },
    },
    watchUser: {
      immediate: true,
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;

        this.hanldeWatchUser();
      },
    },
    watchGroup: {
      immediate: true,
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;

        this.handleWatchUserGroup();
      },
    },
  },
  methods: {
    hanldeWatchUser() {
      const v = this.watchUser;
      // Remove the selected from Member list
      if (v.length > 0) {
        if (this.values[this.mapFields]?.length > 0) {
          this.values[this.mapFields] = this.values[this.mapFields].filter(
            (user) => !v.includes(user),
          );
        }
        this.user_items = this.origin_user_items.filter(
          (user) => !v.includes(user),
        );
      } else {
        this.user_items = this.origin_user_items;
      }
    },
    handleWatchUserGroup() {
      const v = this.watchGroup;

      // Remove the selected from Member Group list
      if (v.length > 0) {
        if (this.selectedUserGroups.length > 0) {
          this.selectedUserGroups = this.selectedUserGroups.filter(
            (group) => !v.includes(group.members),
          );
        }

        this.user_group_items = this.origin_user_group_items.filter(
          (group) => !v.includes(group?.members),
        );
      } else {
        this.user_group_items = this.origin_user_group_items;
      }
    },
    validateRequired() {
      if (this.required && !this.values[this.mapFields]?.length) {
        return "Please select at least one user";
      }
      return true;
    },
    fetchInitUserData(projectId) {
      if (projectId) {
        API.getProjectMemberList(projectId).then((member) => {
          this.origin_user_items = compact(
            uniq([
              this.accountInfo?.username,
              ...(member.map((user) => user.username) || []),
            ]),
          );
          this.hanldeWatchUser();
        });
        API.getGroupList(projectId).then((groupList) => {
          groupList = groupList.filter((item) => item.members != "");
          this.origin_user_group_items = groupList.map((group) => ({
            name: group.name,
            members: group.members,
          }));
          this.handleWatchUserGroup();
        });
      }
    },
    handleChangeGroup() {
      this.setValue(
        this.mapFields,
        this.selectedUserGroups?.join(",").split(",") || [],
      );
      this.$emit("onSelectedGroup", this.selectedUserGroups);
    },
    handleTypeChange() {
      this.setValue(this.mapFields, []);
      this.selectedUserGroups = [];
    },
  },
};
</script>

<style lang="scss">
.user-input-rde {
  .user-list {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    flex-wrap: wrap;
    .user-chip {
      border-radius: 12px;
      background: var(--primary);
      font-size: 12px;
      font-weight: 400;
      padding: 0px 12px;
      line-height: 20px;
      color: white;
    }
  }
  .compositions__radio-group {
    label {
      color: black;
      font-weight: 500;
      padding-left: 10px;
    }
    .v-messages {
      display: none;
    }
  }
}
</style>
