
<Input
  :id="id"
  ref="input"
  :value="newValue"
  :clearable="false"
  :hideDetails="hideDetails"
  :disabled="disabled"
  :errorMessage="innerErrorMessage"
  :appendClass="appendClass"
  v-bind="$attrs"
  v-on="$listeners"
  @paste="handlePaste"
  @keydown="handleKeydown"
  @keypress="handleKeyPress"
  @keyup="handleKeyUp"
/>
