<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <FButton
        :size="size"
        fill
        color="white"
        v-bind="attrs"
        :class="textClass"
        v-on="on"
      >
        <v-icon>mdi-sort-variant</v-icon>
        {{ labelBtn }}
      </FButton>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :class="{ selected: item?.value === selected }"
      >
        <v-list-item-title @click="handleSelected(item?.value)">
          <v-icon v-if="item?.value === selected" size="medium">
            mdi-check
          </v-icon>
          {{ item?.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import FButton from "@/components/fatoms/FButton/FButton.vue";
export default {
  props: {
    items: { type: [Array, String] },
    //label: { type: [String] },
    onSelected: { type: Function },
    size: { type: String, default: "md" },
    clearSelected: { type: Boolean, default: false },
    textClass: { type: String, default: "text-primary" },
    defaultSort: { type: String, default: null },
  },
  components: { FButton },
  data: () => ({
    selected: "",
    labelBtn: "Sort",
  }),
  watch: {
    clearSelected() {
      this.selected = null;
    },
    selected(v) {
      const selectedObj = this.items.find((item) => item.value === v);
      this.labelBtn = "Sort: " + selectedObj?.label;
    },
    items(v) {
      if (v && v.length && this.defaultSort) {
        this.handleSelected(this.defaultSort);
      }
    },
  },
  methods: {
    updateSortButtonName(selected) {
      const selectedObj = this.items.find((item) => item.value === selected);
      this.labelBtn = "Sort: " + selectedObj?.label;
    },
    handleSelected(value) {
      this.selected = value;
      this.onSelected(value);
      this.updateSortButtonName(value);
    },
  },
};
</script>

<style type="scss" scoped>
.selected {
  background-color: #cbcbcb;
  padding-left: 3px;
}

.text-primary {
  color: #321fdb !important;
}
</style>
